import { startCase } from "lodash";

export const camelCaseWords = (str) => {
  return (str || "")
    .split("_")
    .map((word) =>
      word.length > 1 ? `${word[0].toUpperCase()}${word.substr(1)}` : word
    )
    .join(" ");
};

export const joinWords = (str) => {
  return (str || "").toLowerCase().replaceAll(" ", "_");
};

export const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const insertParamsInExpression = (str, params) => {
  let _str = str;
  const _params = [...params];
  const matches = [...str.matchAll(/{(\d?.*?)}/g)];
  for (const match in matches) {
    const paramIndex = match?.[1]?.split(":")[0];
    _str = _str.replace(
      match[1],
      paramIndex ? `${params[+paramIndex]}` : `${_params.shift()}`
    );
  }
  return _str;
};

export const replacePlaceholders = (original, data) => {
  let newValue = original || "";
  Object.keys(data || {})
    .filter((key) => newValue.includes(`{${key}}`))
    .sort((a, b) => (a === "chain" ? 1 : -1))
    .forEach((key) => {
      const existingValueStart = newValue.indexOf(startCase(`${data[key]}`));
      if (key === "chain" && existingValueStart > -1) {
        newValue = newValue.replaceAll(data[key], "");
      }
      newValue = newValue.replaceAll(
        `{${key}}`,
        startCase(`${data[key] || ""}`)
      );
    });
  return newValue;
};

export function getOrdinal(number) {
  // Convert the number to a string to analyze its digits
  const numStr = number.toString();

  // Special cases for 11th, 12th, 13th (they don't follow the usual pattern)
  if (numStr.endsWith("11") || numStr.endsWith("12") || numStr.endsWith("13")) {
    return numStr + "th";
  }

  // Get the last digit of the number
  const lastDigit = numStr.slice(-1);

  // Determine the suffix based on the last digit
  let suffix;
  switch (lastDigit) {
    case "1":
      suffix = "st";
      break;
    case "2":
      suffix = "nd";
      break;
    case "3":
      suffix = "rd";
      break;
    default:
      suffix = "th";
      break;
  }

  return numStr + suffix;
}

export const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const sourceLogos = (source) => {
  return `https://dathic-growth-files-public.s3.amazonaws.com/store-locator-online-option-images/${source?.replaceAll(
    " ",
    ""
  )}.png`;
};
